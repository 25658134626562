/* Custom Scrollbar Styles */
#pokemon-selection::-webkit-scrollbar {
    width: 14px;
    border-radius: 10px;
}

#pokemon-selection::-webkit-scrollbar-track {
    background: linear-gradient(180deg, #ffefd5, #ffe4b5);
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    border: 2px solid #ffcb05;
}

#pokemon-selection::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #ffcb05, #ffa500);
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    border: 2px solid #fff5b7;
}

#pokemon-selection::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #ffa500, #ff8c00);
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.7);
    border: 2px solid #ffe4b5;
}

/* Background with Gradient and Line Pattern */
#selection-screen {
    margin: 0 auto;
    padding: 20px;
    width: 90%;
    text-align: center;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100vh;
    overflow: hidden;
}

/* Improved Title Styling */
.selection-screen h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #ffcb05;
    text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3), -3px -3px 0 #2464b4, 3px -3px 0 #2a75bb, -3px 3px 0 rgba(0, 0, 0, 0.3);
    letter-spacing: 3px;
    text-transform: uppercase;
    animation: titlePulse 2.5s infinite ease-in-out;
    margin-bottom: 15px;
    margin-top: 0;
    text-align: center;
    position: relative;
}

/* Dropdown Section */
#dropdown-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    gap: 5px;
    position: relative;
    z-index: 2;
}

/* "Select Generation" Text Styling */
#dropdown-container label {
    font-size: 1.25rem;
    font-weight: bold;
    font-family: pokemonFont, sans-serif;
    color: #ffffff;
    opacity: 50%;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
    margin-bottom: 5px;
    text-align: center;
    letter-spacing: 2px;
}

/* Enhanced Dropdown Styling */
.styled-select {
    background-color: #ffcb05;
    color: #000;
    border: 2px solid #000;
    border-radius: 10px;
    padding: 10px 15px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.styled-select:hover {
    background-color: #ffb300;
    transform: translateY(-2px);
}

.styled-select:focus {
    border-color: #000; /* Remove yellow glow */
    box-shadow: none;
}

/* Pokémon Grid Styling */
#pokemon-selection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 65vh;
    overflow-y: auto;
    gap: 10px;
    position: relative;
    z-index: 2;
    width: 80%; /* Adjusted width to make it thinner */
    margin: 0 auto; /* Center the grid */
    border-top: 3px solid #ffcd05c6; /* Scroll view separator */
    border-bottom: 3px solid #ffcd05c6; /* Scroll view separator */
    box-shadow: 0px -10px 10px rgba(0, 0, 0, 0.2), 0px 10px 10px rgba(0, 0, 0, 0.2);
}

.pokemon-option {
    margin: 10px;
    cursor: pointer;
    transition: transform 0.2s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 104px;
    height: 104px;
}

.pokemon-option img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    border: 2px solid #ffcb05;
}

.pokemon-option:hover {
    transform: scale(1.1);
}

/* Modern Loading Text Styling */
#loading-text {
    font-size: 2rem;
    font-weight: bold;
    color: #ffcb05;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
    background: linear-gradient(90deg, rgba(255, 203, 5, 0.8), rgba(255, 165, 0, 0.8));
    border: 2px solid #ffcb05;
    border-radius: 10px;
    padding: 10px 20px;
    text-align: center;
    animation: fadeInOut 2s infinite ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: inline-block;
    position: relative;
    z-index: 10;
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
}

/* Multi-Generation Toggle */
#multi-gen-toggle {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    position: relative;
}

#multi-gen-toggle label {
    font-family: 'PokemonFont', sans-serif;
    font-size: 1.5em;
    color: #000;
    margin-left: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

#multi-gen-switch {
    transform: scale(1.5);
    cursor: pointer;
    margin-left: 10px;
}

#multi-gen-toggle .info-icon {
    margin-right: 10px;
    cursor: pointer;
    font-size: 1.2em;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: background-color 0.3s ease;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

#multi-gen-toggle .info-icon:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

#multi-gen-toggle .info-icon:hover #info-popup {
    display: block;
}

#info-popup {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    width: 200px;
    z-index: 10;
    font-size: 0.9em;
}

#info-popup::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
}

/* Loading Spinner Styles */
.loading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh; /* Center vertically */
}

.loading-spinner {
    border: 8px solid rgba(255, 203, 5, 0.3);
    border-top: 8px solid #ffcb05;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s linear infinite;
    margin-bottom: 15px; /* Space between spinner and text */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-container p {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

/* Back Arrow Button */
.back-arrow {
    position: absolute;
    top: 20px;
    left: 40px;
    background-color: #ffcb05;
    color: #000;
    border: none;
    border-radius: 15%;
    font-size: 2.5rem; /* Slightly reduce font size for arrow */
    font-weight: bold;
    width: 65px;
    height: 60px;
    padding-bottom: 7px; /* Center the arrow */
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1; /* Align arrow vertically */
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.back-arrow:hover {
    background-color: #ffa500;
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}

/* Overlay */
.confirm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Darker overlay for depth */
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 999;
}

/* Popup Container */
.confirm-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #1e1e2e;
    border: 3px solid #ffcb05;
    border-radius: 15px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.7); /* Stronger shadow for depth */
    color: #f5f5f5;
    font-family: 'Arial', sans-serif;
    width: 600px; /* Slightly wider */
    height: 600px; /* Taller for better proportions */
    display: flex;
    overflow: hidden; /* Prevent content overflow */
    z-index: 1000;
}

/* Left Section: Sprite + Moves */
.popup-left {
    background: #2c2c4e;
    padding: 20px;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 2px solid #ffcb05;
}

.popup-sprite {
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    background: #1e272e;
    border: 4px solid #ffcb05;
    border-radius: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

/* Moves List Container */
.popup-moves {
    display: flex;
    flex-direction: column;
    gap: 11px; /* Reduced gap for cleaner look */
    margin-top: 10px;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    padding: 8px;
    background: #2c2c4e; /* Flat dark background */
    border-radius: 10px;
    border: 1px solid #444;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
}

/* Individual Move Styling */
.popup-move {
    cursor: pointer;
    background: #3a3a5a; /* Solid background for contrast */
    color: #ffcb05; /* Bright text for readability */
    padding: 6px 10px;
    border-radius: 6px;
    font-size: 1rem; /* Increased text size */
    font-weight: bold;
    text-align: center;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    transition: transform 0.15s ease, box-shadow 0.15s ease, background 0.15s ease, color 0.15s ease;
}

/* Selected Move */
.popup-move.selected {
    background-color: #ffcb05; /* Highlight with yellow */
    color: #2a2a4d; /* Dark blue text for contrast */
    border: 2px solid #0000006c; /* Clear indication of selection */
    box-shadow: 0 0 10px rgba(255, 203, 5, 0.6); /* Glow effect */
    transform: scale(1.02); /* Slight scale-up */
}

/* Hover Effect */
.popup-move:not(.selected):hover {
    background: #4b4478; /* Slightly lighter purple on hover */
    color: #fff; /* White text on hover */
    transform: translateY(-2px); /* Lift effect */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

/* Disabled State for Moves */
.popup-move.disabled {
    background: #1e1e35; /* Dark muted color for disabled moves */
    color: #888; /* Gray text */
    cursor: not-allowed;
    box-shadow: none;
    transform: none;
    border: none;
}

/* Message for Remaining Moves */
.remaining-moves {
    margin-top: 10px;
    color: #ffcb05; /* Yellow text for emphasis */
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    padding: 8px 0;
    transition: color 0.3s ease;
}

/* Message Container for Remaining Moves */
.select-moves-message {
    font-family: Verdana;
    margin-top: 20px;
    color: #ffffff; /* Yellow text for the theme */
    font-size: 0.8rem; /* Clean and readable size */
    font-weight: bold;
    opacity: 80%;
    text-align: center;
    transition: color 0.3s ease, transform 0.2s ease; /* Smooth updates */
}

/* Parent Container Styling for Moves */
.move-container {
    display: flex;
    flex-direction: column; /* Stack moves vertically */
    gap: 8px;
    margin-top: 10px;
    padding: 10px;
    background-color: #1e1e35; /* Dark theme background */
    border: 2px solid #3a3a5a;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.popup-moves .no-moves {
    color: #ffcb05;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
}

/* Scrollbar Styling */
.popup-moves::-webkit-scrollbar {
    width: 6px;
}

.popup-moves::-webkit-scrollbar-track {
    background: #2c2c4e;
    border-radius: 10px;
}

.popup-moves::-webkit-scrollbar-thumb {
    background: #ffcb05; /* Bright yet clean thumb */
    border-radius: 10px;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5);
}

.popup-moves::-webkit-scrollbar-thumb:hover {
    background: #f39c12;
}


/* Type Effectiveness Section */
.type-effectiveness {
    margin-top: 15px; /* Adds spacing after stats */
    padding: 10px;
    background: #2c2c4e; /* Flat dark background for consistency */
    border: 2px solid #ffcb05;
    border-radius: 10px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 1rem;
    color: #f5f5f5;
    line-height: 1.6;
}

.type-effectiveness p {
    margin: 5px 0; /* Adjusts spacing for individual rows */
    display: flex;
    justify-content: space-between;
    color: #ffcb05;
    font-weight: bold;
}

.type-effectiveness strong {
    color: #f39c12; /* Highlight for titles like "Strong" and "Weak" */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Updated Right Section */
.popup-right {
    padding: 20px;
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #252542;
    font-family: 'courier new';
    overflow-y: auto; /* Allow scrolling if content overflows */
}

.popup-right h3 {
    font-family: pokemonFont;
    font-size: 1.75rem;
    margin-bottom: 15px;
    color: #ffcb05;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.popup-stats {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1.1rem;
    font-weight: bold;
}

.popup-stats .stat-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.popup-stats .stat-label {
    color: #ffcb05;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    font-weight: bold;
    text-align: left; /* Ensure labels align to the left */
}

.popup-stats .stat-value {
    color: #f5f5f5;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    text-align: right; /* Ensure values align to the right */
}

/* Confirm/Cancel Buttons */
.popup-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}

.confirm-btn, .cancel-btn {
    padding: 10px 18px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    text-transform: uppercase;
}

.confirm-btn {
    background: linear-gradient(90deg, #ffcb05, #f39c12);
    color: #1e1e2e;
    box-shadow: 0 4px 6px rgba(255, 203, 5, 0.3);
}

.confirm-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 10px rgba(255, 203, 5, 0.5);
}

.cancel-btn {
    background: linear-gradient(90deg, #888, #666);
    color: #f5f5f5;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.cancel-btn:hover {
    transform: scale(1.05);
    background: linear-gradient(90deg, #777, #555);
}

.popup-stats {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Spacing between rows */
    font-size: 1.1rem;
    font-weight: bold;
}

.stat-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0; /* Spacing above and below each row */
    border-bottom: 1px solid #444; /* Adds a subtle line between rows */
}

.stat-row:last-child {
    border-bottom: none; /* Remove border for the last row */
}

.stat-label {
    color: #ffcb05; /* Bright yellow for labels */
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.stat-value {
    color: #f5f5f5; /* Clean white for values */
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    font-weight: normal;
}
