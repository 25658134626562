@font-face {
    font-family: 'PokemonFont';
    src: url('/public/pokemon_font/pixelated text font/PKMN_RBYGSC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    background: linear-gradient(135deg, #133a64 0%, #1d5093 50%, #11365e 100%);
    color: #ecf0f1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

/* Pattern overlay */
body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.05) 0px,
        rgba(255, 255, 255, 0.05) 2px,
        transparent 2px,
        transparent 8px
    );
    z-index: -1;
    pointer-events: none;
}

.particle {
    position: absolute;
    background: rgba(255, 203, 5, 0.7);
    border-radius: 50%;
    pointer-events: none;
    animation: float 5s infinite;
}

@keyframes float {
    0% {
        transform: translateY(0) translateX(0);
    }

    50% {
        transform: translateY(-100px) translateX(100px);
    }

    100% {
        transform: translateY(0) translateX(0);
    }
}

.notification {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 20px;
    border-radius: 5px;
    font-size: 1.2em;
    color: #fff;
    background: #333;
    opacity: 0;
    transition: opacity 0.3s, transform 0.3s;
    z-index: 200;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

    .notification.show {
        opacity: 1;
        transform: translate(-50%, 20px);
    }

    .notification.success {
        background: #4caf50;
    }

    .notification.error {
        background: #f44336;
    }

@keyframes slideDown {
    0% {
        transform: translateX(-50%) translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
