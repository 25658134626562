#main-menu {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: backgroundScroll 60s linear infinite;
    padding: 0 600px 0 0;
    background: linear-gradient(135deg, #133a64 0%, #1d5093 50%, #11365e 100%);
    position: relative;
    overflow: hidden;
    margin: 0;
}

    /* Add Pokéball pattern overlay */
    #main-menu::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: repeating-linear-gradient( 45deg, rgba(255, 255, 255, 0.05) 0px, rgba(255, 255, 255, 0.05) 2px, transparent 2px, transparent 8px );
        z-index: 1;
        pointer-events: none;
    }

#title-logo {
    display: block;
    margin: 0 auto 20px;
    width: 80%;
    max-width: 450px;
    animation: logoFloat 3s ease-in-out infinite;
    transform: scale(1.5);
    padding: 70px 0 20px;
    position: relative;
    z-index: 2;
    filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.2));
}

@keyframes logoFloat {
    0%, 100% {
        transform: scale(1.5) translateY(0);
    }

    50% {
        transform: scale(1.5) translateY(-5px);
    }
}

#welcome-message {
    margin: 0px;
    width: 850px;
    font-family: 'PokemonFont', sans-serif;
    animation: fadeIn 2s ease-in-out;
    position: relative;
    z-index: 2;
}

    #welcome-message h1 {
        margin: 0px 0 30px;
        font-size: 2.8em;
        color: #ffcb05;
        text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3), -3px -3px 0 #2464b4, 3px -3px 0 #2a75bb, -3px 3px 0 rgba(0, 0, 0, 0.3);
        animation: titlePulse 3s infinite;
        line-height: 1.2;
        letter-spacing: 3px;
    }

    #welcome-message p {
        margin-top: 15px;
        font-size: 1.5em;
        color: #fff;
        text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3), -2px -2px 0 #1a4b8c, 2px -2px 0 #1a4b8c, -2px 2px 0 #1a4b8c;
        line-height: 1.4;
        letter-spacing: 1.25px;
    }

/* Reverting back to original battle selection styles */
#selection-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto;
    top: 80px;
}

#selection-ui {
    position: absolute;
    top: -120px;
    width: 700px;
    height: 350px;
    z-index: 0;
    background: url('/public/BattleSelectionUI.png') no-repeat center;
    background-size: contain;
}

    #selection-ui:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

#battle-selection {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    width: 60%;
    height: 100%;
    position: relative;
    top: -30px;
    align-items: center;
    justify-items: center;
}

.menu-option {
    background: transparent;
    font-family: 'PokemonFont', sans-serif;
    font-size: 2.05em;
    font-weight: bold;
    color: #000000;
    text-shadow: 3px 3px 0px rgba(255, 203, 5, 0.3);
    text-align: center;
    padding: 5px;
    margin-right: 10px;
    cursor: default;
    transition: color 0.2s, transform 0.2s;
    position: relative;
}

    .menu-option:hover {
        color: #ffb300;
        transform: scale(1.1);
    }

    .menu-option:active {
        transform: scale(0.95);
    }

    .menu-option.selected {
        border-color: #ffcb05;
    }

/* Enhanced changelog styles */
#changelog {
    background: linear-gradient(135deg, #325778 0%, #1a4b8c 100%);
    border-radius: 15px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.7), inset 0 0 50px rgba(0, 0, 0, 0.5);
    width: 22%; /* Increased width */
    padding: 20px;
    animation: fadeIn 1.5s ease-in-out;
    font-family: 'PokemonFont', sans-serif;
    position: absolute;
    top: 50%;
    right: 16%; /* Adjusted position */
    transform: translateY(-50%);
    height: 75%;
    overflow: hidden;
    border: 3px solid #ffcb05;
    transition: all 0.3s ease;
}

    #changelog:hover {
        transform: translateY(-50%) scale(1.02);
    }

    #changelog h2 {
        margin: 0;
        font-size: 2em;
        color: #ffcb05;
        text-shadow: 2px 2px 0 #2a75bb, -2px -2px 0 #2a75bb, 2px -2px 0 #2a75bb, -2px 2px 0 #2a75bb;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        padding: 15px 0;
        z-index: 1;
    }

#changelog-content {
    text-align: left;
    font-size: 1.1em;
    line-height: 1.6;
    color: #ffffff;
    height: calc(100% - 60px);
    overflow-y: auto;
    padding: 0 15px 20px 0;
    box-sizing: border-box;
    --paragraph-spacing: 20px;
}

    #changelog-content p {
        margin-bottom: var(--paragraph-spacing);
        padding-left: 15px;
        border-left: 3px solid rgba(255, 203, 5, 0.4);
        padding-right: 10px;
        position: relative;
    }

        #changelog-content p::before {
            content: '►';
            color: #ffcb05;
            position: absolute;
            left: -20px;
            opacity: 0.8;
        }

    #changelog-content::-webkit-scrollbar {
        width: 10px;
    }

    #changelog-content::-webkit-scrollbar-track {
        background: rgba(42, 117, 187, 0.3);
        border-radius: 8px;
    }

    #changelog-content::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #ffcb05 0%);
        border-radius: 8px;
        border: 2px solid #2a75bb;
    }

.customization-tab {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 125px;
    background: rgba(19, 58, 100, 0.95);
    border-right: 3px solid #ffcb05;
    z-index: 10;
    color: white;
    font-family: 'PokemonFont', sans-serif;
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@keyframes backgroundScroll {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 100%;
    }
}

@keyframes titlePulse {
    0%, 100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.03);
    }
}
