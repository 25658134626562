/* Page Layout */
.settings-page {
    position: absolute; /* Ensure absolute positioning */
    top: 20px; /* Start from the top */
    left: 20px; /* Start from the left */
    width: auto; /* Content width adjusts to fit content */
    height: auto; /* Content height adjusts to fit content */
    padding: 20px;
    box-sizing: border-box;
    font-family: 'PokemonFont', sans-serif;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align everything to the left */
    justify-content: flex-start; /* Ensure items align to the top */
}

/* Settings Header */
.settings-header {
    font-size: 3rem;
    color: #ffcb05;
    text-shadow: 2px 2px 0 #000000;
    font-weight: bold;
    margin-bottom: 30px; /* Space below Settings title */
    text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.3), -3px -3px 0 #2464b4, 3px -3px 0 #2a75bb, -3px 3px 0 rgba(0, 0, 0, 0.3);
    animation: titlePulse 1s infinite;
    line-height: 1.2;
    letter-spacing: 3px;
}

/* Profile Section */
.profile-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;
    padding: 30px;
    border: 3px solid rgba(255, 203, 5, 0.6); /* Gold transparent border */
    border-radius: 25px; /* Rounded edges for the box */
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.6); /* Soft shadow for depth */
    width: 1675px; /* Set a fixed width */
    height: 250px; /* Set a fixed height */
}

/* Profile Header */
.profile-header {
    font-size: 2rem;
    color: #ffcb05;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.4);
    margin-bottom: 15px;
}

/* Profile Item */
.profile-item {
    margin-top: 10px;
    font-size: 1.6rem;
    display: flex;
    flex-direction: column; /* Stack title and value */
}

.profile-item h2 {
    margin-bottom: 5px;
}

.profile-item p {
    font-size: 1.4rem;
    color: #ffffff;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
}

/* Back Arrow Button */
.settings-back-arrow {
    position: absolute;
    top: 20px;
    right: 30px;
    background-color: #ffcb05;
    color: #000;
    border: none;
    border-radius: 15%;
    font-size: 3.25rem; /* Slightly reduce font size for arrow */
    font-weight: bold;
    width: 100px;
    height: 60px;
    padding-bottom: 7px; /* Center the arrow */
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1; /* Align arrow vertically */
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.settings-back-arrow:hover {
    background-color: #ffa500;
    transform: scale(1.1);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
}
