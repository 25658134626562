#battle-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    width: 100%;
    color: #333;
    position: relative;
}

#battle-arena {
    background: url('/public/background.png') no-repeat center center;
    background-size: cover;
    width: 1500px;
    height: 750px;
    margin: 0 auto;
    position: relative;
    border: 10px solid #ffffff;
    border-radius: 25px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.6);
    padding: 15px;
    z-index: 1;
    /* Hides anything outside the arena */
    overflow: hidden;
}

@keyframes slide-in-left {
    from {
        transform: translateX(-200%); /* Start far left */
    }
    to {
        transform: translateX(0); /* End at original position */
    }
}

@keyframes slide-in-right {
    from {
        transform: translateX(200%); /* Start far right */
    }
    to {
        transform: translateX(0); /* End at original position */
    }
}

#player-area,
#opponent-area {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 200px;
    z-index: 2;
}

#player-area {
    left: 13%;
    bottom: -3%;
}

#opponent-area {
    right: 20%;
    top: 26%;
}

/* Player sprite */
.pokemon-image-player {
    width: 400px; /* Adjust size for player sprite */
    height: auto;
    animation: slide-in-left 2s ease-out forwards;
}

/* Opponent sprite */
.pokemon-image-opponent {
    width: 275px; /* Adjust size for opponent sprite */
    height: auto;
    animation: slide-in-right 2s ease-out forwards;
}

.pokemon-name {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
}

.pokemon-level {
    font-size: 1em;
    color: #555;
}

#battle-ui {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    z-index: 3;
}

#battle-selection-menu {
    position: absolute;
    bottom: 5%;
    right: 3%;
    width: 500px;
    height: 250px;
    background: url('/public/BattleSelectionUI.png') no-repeat center center;
    background-size: contain;
    display: grid;
    grid-template-areas:
        "fight bag"
        "pokemon run";
    grid-template-columns: 1fr 1fr;
    justify-items: left;
    align-items: left;
    padding: 80px 40px;
    gap: 20px;
    box-sizing: border-box;
    z-index: 10;
}

.battle-menu-option {
    font-family: 'PokemonFont', sans-serif;
    font-size: 1.75em;
    color: #000;
    font-weight: bold;
    text-shadow: 3px 3px 0 rgba(59, 59, 59, 0.25);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-align: center;
    line-height: 1;
    padding: 5px 20px;
}

.battle-menu-option:hover {
    text-shadow: 0 0 5px #ffcc00, 0 0 10px #ffaa0063;
    color: #ffb300;
    transform: scale(1.1);
}

/* Container for the move buttons */
.moves-container {
    display: grid; /* Grid layout for moves */
    grid-template-columns: repeat(2, 1fr); /* Two moves per row */
    gap: 20px; /* Increase spacing between moves */
    position: absolute; /* Absolute positioning for the move list */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust position for centering */
    width: 350px; /* Increased width for more space */
    padding: 20px; /* Add padding to give moves more room */
    background-color: transparent; /* Transparent background */
}

/* Individual move buttons */
.move-button {
    font-family: 'PokemonFont', sans-serif;
    font-size: 1.2em; /* Increase font size for better readability */
    color: #000;
    font-weight: bold;
    text-shadow: 3px 3px 0 rgba(59, 59, 59, 0.25); /* Keep the text-shadow for style */
    cursor: pointer;
    text-align: center;
    display: flex; /* Flexbox for centering content */
    align-items: center; /* Vertically center the text */
    justify-content: center; /* Horizontally center the text */
    padding: 5px 0px 10px 0px; /* Slight padding adjustment */
    background-color: transparent; /* Remove background */
    border: none; /* Remove border */
    box-shadow: none; /* Remove box shadow */
    overflow-wrap: break-word; /* Ensure text breaks properly */
    white-space: normal; /* Ensure proper wrapping */
}

.move-button:hover {
    text-shadow: 0 0 5px #ffcc00, 0 0 10px #ffaa0063; /* Keep hover effect */
    color: #ffb300;
    transform: scale(1.1); /* Add subtle hover zoom */
}

/* Back button styling */
.back-button {
    font-family: 'PokemonFont', sans-serif;
    font-size: 1.25em;
    font-weight: bold;
    color: #000;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    text-align: center;
    padding: 10px 30px;
    position: absolute; /* Position the back button absolutely */
    bottom: 10px; /* Adjust the positioning closer to the moves list */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    background-color: #f7c600; /* Yellow background */
    border-radius: 8px;
    border: 3px solid #000000; /* Black border */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, background-color 0.2s ease;
    transform-origin: center; /* Prevent shifting */
}

.back-button:hover {
    background-color: #ffaa00; /* Darker yellow */
    color: #fff;
    transform: scale(1.05) translateX(-48%); /* Ensure centering while scaling */
}

/* Action Prompt Box */
#action-prompt-box {
    position: absolute;
    bottom: 0%; /* Align with the action menu */
    right: 32%; /* Place to the left of the action menu */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3; /* Ensure it shows above other elements */
    width: 425px; /* Adjustable width for customization */
    height: 275px; /* Adjustable height for customization */
    pointer-events: none; /* Ensure it's non-interactable */
    background: url('/public/ActionMessageUI.png') no-repeat center center; /* Correct image path */
    background-size: contain;
}

/* Action Prompt Text */
.action-prompt-text {
    font-family: 'PokemonFont', sans-serif; /* Pokémon font */
    font-size: 1.5em;
    color: #e0e0e0; /* White text for better visibility */
    font-weight: bold;
    text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for contrast */
    text-align: left; /* Center-align the text */
    margin: 0; /* Remove default margin */
    width: 70%; /* Prevent overflow */
    padding-right: 40px;
    line-height: 1.2; /* Adjust spacing for better readability */
    pointer-events: none; /* Non-interactable */
}

#battle-move-text {
    font-family: 'PokemonFont', sans-serif;
    font-size: 1.25em;
    color: #000;
    font-weight: bold;
    text-shadow: 3px 3px 0 rgba(59, 59, 59, 0.25);
    cursor: pointer;
    text-align: center;
    line-height: 1;
    padding: 5px 20px;
}

/* Secondary battle menu */
#secondary-battle-menu {
    position: absolute;
    bottom: 5%;
    right: 33%; /* Adjusted to be to the left of the main menu */
    width: 425px;
    height: 250px;
    background: url('/public/BattleSelectionUI.png') no-repeat center center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    z-index: 5;
}

#secondary-battle-menu p {
    font-family: 'PokemonFont', sans-serif;
    text-shadow: 2px 2px 0 rgba(59, 59, 59, 0.25);
    font-size: 1.25em;
    color: #333;
    font-weight: bold;
    margin: 0;
    padding: 25px;
}


/* Adjust Pokémon info boxes to top corners */
#player-info {
    position: absolute;
    top: 30px;
    left: 50px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px
}

#opponent-info {
    position: absolute;
    top: 30px;
    right: 50px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 200px;
}

/* Pokémon info box styling */
.pokemon-info-box {
    position: relative;
    width: 300px;
    height: 90px;
    background: #f9f5d7; /* Light cream background */
    border: 5px solid #363636; /* Dark greenish border */
    border-radius: 10px;
    box-shadow: 4px 4px 0px #4a473a87; /* Retro-style shadow */
    padding: 10px; /* Add padding for spacing */
    font-family: 'PokemonFont', sans-serif;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out elements vertically */
}

/* Pokémon Name */
.pokemon-info-box .pokemon-name {
    font-size: 24px;
    font-weight: bold;
    color: #000;
    text-shadow: -1px -1px 0px #25252599;
    margin: 0; /* Remove extra margin */
}

/* Level styling */
.pokemon-info-box .level {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    position: absolute;
    top: 12px;
    right: 10px;
    margin: 0; /* Remove extra margin */
}

/* Updated HP bar wrapper */
.pokemon-info-box .hp-bar-wrapper {
    display: flex;
    align-items: center; /* Vertically align label and bar */
    justify-content: flex-start; /* Align items to the start */
    width: 97%; /* Full width of the info box */
    background: #000; /* Black background for container */
    border-radius: 16px; /* Rounded edges */
    padding: 6px; /* Add spacing inside wrapper */
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Subtle shadow */
    margin-top: auto; /* Push to the bottom of the container */
    gap: 10px; /* Add space between the label and the bar */
}

/* HP Label */
.pokemon-info-box .hp-label {
    font-family: 'PokemonFont', sans-serif;
    font-size: 16px; /* Slightly larger font for readability */
    color: #FFD700; /* Yellow color for label */
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Subtle shadow */
}

/* HP Bar Container */
.pokemon-info-box .hp-bar-container {
    flex: 1; /* Take up the remaining space */
    height: 12px; /* Adjusted height for balance */
    background: #4a473a; /* Dark border background */
    border-radius: 10px; /* Rounded edges */
    overflow: hidden; /* Prevent overflow */
    display: flex; /* Allow alignment */
    align-items: center; /* Center the bar vertically */
    margin: 0; /* Remove any extra margin */
}

/* HP Bar Fill */
.pokemon-info-box .hp-bar {
    height: 100%;
    background: #65c963; /* Solid green for full health */
    transition: width 0.5s ease; /* Smooth transition for health changes */
    margin: 0; /* Remove extra margin to avoid shifting */
}

/* HP Counter Styling */
.pokemon-info-box .hp-counter {
    font-family: 'PokemonFont', sans-serif;
    font-size: 20px;
    color: #333; /* Dark text for visibility */
    text-align: center; /* Center-align the text */
    margin-top: 5px; /* Spacing from the health bar */
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5); /* Subtle glow */
    font-weight: bold;
}

#loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.332); /* Dark overlay to emphasize the center elements */
}

#loading-spinner {
    width: 200px; /* Adjusted size for better proportions */
    height: 200px;
    background-image: url('/public/PokeballLogoTransparent.png'); /* Reference to the Pokéball asset */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    animation: spin 1.2s ease-in-out infinite; /* Smoothened spinning effect */
    z-index: 10000;
    filter: drop-shadow(0 0 15px rgba(242, 242, 242, 0.5)); /* Glowing effect for modern aesthetics */
    opacity: 75%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#loading-overlay p {
    font-family: 'PokemonFont', sans-serif;
    font-size: 2em; /* Larger text for emphasis */
    color: #ffffff;
    margin-top: 30px;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8); /* Stronger shadow for readability */
    z-index: 10000;
    animation: fade-in 1.5s ease-in-out infinite alternate; /* Subtle fading animation */
    letter-spacing: 0.1em; /* Slight spacing for modern typography */
}

@keyframes fade-in {
    0% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}

