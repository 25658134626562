/* Login and Register Screen */
.login-register-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: radial-gradient(circle, rgba(44, 62, 80, 0.9), rgba(0, 0, 0, 0.9));
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    animation: fadeIn 1.5s;
    overflow: hidden; /* Prevent scrollbars */
}

    .login-register-screen::before {
        content: "";
        position: absolute;
        top: 45%;
        left: 5%;
        width: 500px;
        height: 500px;
        background-image: url('/public/PokeballLogoTransparent.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.04;
        z-index: -1;
        transform: rotate(40deg);
        animation: rotateSlow 20s linear infinite;
    }

    .login-register-screen::after {
        content: "";
        position: absolute;
        top: 0%;
        right: 5%;
        width: 500px;
        height: 500px;
        background-image: url('/public/PokeballLogoTransparent.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: 0.04;
        z-index: -1;
        transform: rotate(-40deg);
        animation: rotateSlow 20s linear infinite reverse;
    }

/* Keyframe animation for switching forms */
@keyframes formSwitch {
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

.switch-animation {
    animation: formSwitch 0.8s ease forwards;
}

@keyframes popIn {
    from {
        transform: scale(0.8);
        opacity: 0;
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

.login-register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.login-register-box {
    background: linear-gradient(145deg, #2c3e50, #34495e);
    padding: 50px 30px;
    border-radius: 15px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.85);
    text-align: center;
    width: 500px;
    max-width: 90%;
    box-sizing: border-box;
    animation: popIn 1s;
    position: relative;
    z-index: 1;
    border: 4px ridge rgba(255, 204, 5, 0.5);
}

    .login-register-box h2 {
        font-family: 'PokemonFont', sans-serif;
        color: #ffcb05;
        margin-bottom: 50px;
        font-size: 2.5em;
        animation: glow 1.5s infinite alternate;
        padding-bottom: 0px;
    }

    .login-register-box input {
        width: 100%;
        padding: 15px;
        margin: 10px 0;
        border-radius: 5px;
        border: 1px solid #bdc3c7;
        font-size: 1em;
        background: #bfc5c9;
        box-sizing: border-box;
        transition: all 0.3s ease-in-out;
    }

        .login-register-box input:focus {
            border: 1px solid #ffcb05;
            box-shadow: 0 0 10px rgba(255, 203, 5, 0.8);
        }

.login-register-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

    .login-register-buttons button {
        flex: 1;
        padding: 12px 0;
        margin: 0 5px;
        border: none;
        border-radius: 10px;
        background: linear-gradient(145deg, #ffcb05, #ffb300);
        color: #000;
        font-size: 1.2em;
        font-family: 'Lucida Sans Regular', sans-serif;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    }

        .login-register-buttons button:hover {
            background: linear-gradient(145deg, #ffb300, #ffcb05);
            transform: translateY(-3px);
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
        }

        .login-register-buttons button:active {
            transform: translateY(1px);
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
        }

        .login-register-buttons button:last-child {
            background: linear-gradient(145deg, #e67e22, #d35400);
            color: #fff;
        }

            .login-register-buttons button:last-child:hover {
                background: linear-gradient(145deg, #d35400, #e67e22);
            }

.age-confirmation {
    font-family: 'PokemonFont', sans-serif;
    color: #ecf0f1;
    font-size: 0.9em;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 30px;
    padding-top: 20px;
    line-height: 1.5;
    animation: fadeIn 2s;
}

.forgot-password {
    font-family: Calibri;
    margin-top: 0px;
    margin-bottom: 30px;
    margin-left: 10px;
    text-align: left;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
}

    .forgot-password a {
        color: #3498db;
        text-decoration: none;
        font-size: 1em;
        transition: color 0.2s ease;
    }

        .forgot-password a:hover {
            color: #2980b9;
        }

.login-register-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: -1;
}

/* Loader Spinner */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.loader {
    border: 10px solid rgba(255, 255, 255, 0.3);
    border-top: 10px solid #ffcb05;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes rotateSlow {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.link-button {
    background: none;
    border: none;
    color: #3498db;
    text-decoration: none;
    font-size: 1em;
    cursor: pointer;
    transition: color 0.2s ease;
    padding: 0;
}

    .link-button:hover {
        color: #2980b9;
    }

/* Email Confirmation Screen - New CSS for improved visuals */
.email-confirmation {
    text-align: center;
    padding: 0px 20px;
}

    .email-confirmation p {
        font-family: 'PokemonFont', sans-serif;
        color: #ffffff;
        font-size: 1.2em;
        margin-bottom: 30px;
        line-height: 1.5;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
    }

    .email-confirmation button {
        padding: 10px 20px;
        background: linear-gradient(145deg, #ffcb05, #ffb300);
        border: none;
        color: #000;
        font-size: 1.2em;
        border-radius: 10px;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    }

        .email-confirmation button:hover {
            background: linear-gradient(145deg, #ffb300, #ffcb05);
            transform: translateY(-3px);
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.6);
        }

        .email-confirmation button:active {
            transform: translateY(1px);
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
        }

.password-instruction {
    font-family: 'PokemonFont', sans-serif; /* Use the same font as in the register section */
    color: #ffffff; /* Adjust the color if needed */
    font-size: 14px; /* Adjust size if you want it larger or smaller */
    text-align: center;
    line-height: 1.5;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
    word-spacing: 3px;
    margin-top: 10px;
}

    /* Style for the first paragraph */
    .password-instruction:first-of-type {
        margin-bottom: 20px; /* Larger bottom margin for separation */
        margin-top: 20px;
    }

    /* Style for the second paragraph */
    .password-instruction:nth-of-type(2) {
        margin-top: 0px; /* Separate it from the first paragraph */
        margin-bottom: 0;
    }
